#contactForm {
    margin: 2rem 0;
}

/* bold text 100px and position in center*/
.container-contactForm h4 {
    font-weight: 100px;
    text-align: center;
    margin-bottom: 1rem;
}

/* make higher 200px */
.inscreaseHeight {
    height: 180px;
}

/* style button Valider */
.btnValider {
    border-radius: 60px!important;
    background-color: var(--secondary-color);
    color: white;
    border: solid 0px;
    padding: 8px 15px;
    font-size: var(--subtitle-font-size);;
    margin: 0 auto;
}

/* change background of the component ContactForm into white, with no border */
.container-contactForm{
    background-color: white;
    border: 0 solid #dedede;
}

/* Only image in this component have max-width 100%, no margin, no radius */
.container-contactForm img {
    max-width: 100%;
    max-height: 65%;
    margin-right: 0;
    border-radius: 0;
}

/* Mobile version */
@media screen and (max-width: 700px) {
    /* Help responsive for mobile version */
    .col {
        flex-basis: 100%;
    }
}