.politique__header{
    padding-top: 10%;
    height: 100vh;
}

.politique__header h1 {
    text-align: center;
    margin: 1rem 0 1.5rem;
    position: relative;
    padding-bottom: 12px;
    color: #90268e;
    font-size: 2.3rem;
    font-weight: 750;
    letter-spacing: 1px;
}

.politique__header h1:before {
    margin: auto;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 25%;
    height: 5px;
    background: #90268e;
    border-radius: 10px;
}