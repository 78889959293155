/* style for Item */
.journalFabry-Item{
    border-bottom: solid 0.2px rgba(0, 0, 0, 0.236);
    padding: 5%;
    margin-bottom: 15px;
    height:80vh;
}
.journalFabry-Item img{
    width: 315px;
    height: 450px;
    margin: 10 auto;
    display: block;
}
.actualite-text-Download {
    float: left;
}
.Journal-text {
    padding-top: 2%;
}

#journalFabry-page h4 {
    font-size: var(--subtitle-font-size);
}

/* style pagination */

.Paginate {
    margin-top: 50px;
}
.ul.pagination li {
    box-shadow: 0px 0px 0px gray;
  }
  
  ul.pagination li a {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    width: 50px;
    height: 45px;
    margin: 0.5px;
    border-radius: 60px;
    border: 1px solid gray;
  }
  .pagination li.active {
      background-color: rgba(255, 255, 255, 0);
  }


@media(max-width: 1000px) {
    .journalFabry-Item{
        border-bottom: solid 0.2px black;
        padding: 5%;
        height:90vh;
    }
    .Paginate {
        margin-right: 2em !important;
    }
    .Paginate ul li a {
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        width: 20px;
        height: 20px;
        margin: 0.2px;
        border-radius: 60px;
        border: 1px solid gray;
        padding: 0px !important;
        margin-top: 20px;
      }
      
}
