.container__message {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  overflow-wrap: break-word;
  min-width: 250px;
}

/* Darker chat container */
.darker {
  border-color: #ccc;
  background-color: #ddd;
}

/* Clear floats */
.container__message::after {
  content: "";
  clear: both;
  display: table;
}

/* Style images */
.container__message img {
  float: left;
  max-width: 60px;
  margin-right: 20px;
  border-radius: 50%;
}

/* Style the right image */
.container__message img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}

.message-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.avatar {
  background-color: "#04295D";
}
