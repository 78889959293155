/* Create background with the picture APMF */
.team {
    background-image: url("../../../../images/site/apmf.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 6rem;
}
  
/* Position for the team_title */
.team_header {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  margin-top: -20px;
  padding: 100px 20px 500px 20px;
}
  
/* Style for the text in the center of the picture */
.team_title {
  font-family: "Roboto", sans-serif;
  font-size: var(--subtitle-font-size);;
  background-color: var(--primary-color-medium);
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 20px 40px 20px 40px;
}
.team_title b{
  font-size: var(--subtitle-font-size);;
}

/* Style for the text in the bottom of the picture */
.textBienvenue {
  padding: 5%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  min-height: 300px;
  background-color: var(--primary-color-light);
  color: white;
}
  
/* Mobile version */
@media screen and (max-width: 700px) {

  /* Resize 2 texts for mobile version */
  .team_title {
    font-size: 15px;
  }
  .textBienvenue {
    margin-top: -150px;
    padding-top: 55px;
    font-size: 15px;
    min-height: 270px;

  }

}

  
  