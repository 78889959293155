.adminNav {
  position: -webkit-sticky !important; /*Safari*/
  position: sticky !important;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #04295d;
  transition: all 0.3s;
  height: 100vh;
  width: 20%;
}

.adminNav.active {
  width: 7%;
}

.adminNav__header {
  padding: 1rem;
  display: flex;
  justify-content: left;
  color: white;
  width: 120px;
  height: 60px;
}

.adminNav__header img {
  background-size: cover;
}
.adminNav__header p {
  width: 70px;
  object-fit: contain;
  margin-top: 1em;
}

.adminNav.active .adminNav__header img {
  text-align: center;
}

.adminNav__title {
  text-align: center;
  color: white;
}

.adminNav.active .adminNav__title {
  display: none;
}

.adminNav__links {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.adminNav.active {
  overflow: hidden;
}

.adminNav__links::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.adminNav__links::-webkit-scrollbar-track {
  background: rgb(65, 63, 63); /* color of the tracking area */
}

.adminNav__links::-webkit-scrollbar-thumb {
  background-color: #61e0e6; /* color of the scroll thumb */
  /* border-radius: 20px; roundness of the scroll thumb */
  /* border: 3px solid rgb(0, 0, 0); creates padding around scroll thumb */
}

.adminNav__link {
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  background-color: white; /* #25bbe8 => aprés click */
  position: relative;
}

.adminNav__link:active {
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  background-color: white; /* #25bbe8 => aprés click */
  position: relative;
}

.adminNav__link-deconnexion {
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  background-color: red;
  position: relative;
}

.adminNav__nouvelles_commandes__indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  background-color: rgb(17, 17, 17);
  color: rgb(233, 233, 233);
  padding: 0 5px;
  font-size: 14px;
  border-radius: 50%;
}

.adminNav__link p {
  margin: 0;
  opacity: 1;
  text-align: left;
}

.adminNav__link-deconnexion p {
  margin: 0;
  opacity: 1;
  text-align: left;
}
.adminNav__link-deconnexion:hover {
  color: white;
}
.adminNav.active .adminNav__link p {
  display: none;
}

.adminNav.active .adminNav__link-deconnexion p {
  display: none;
}

.adminNav__link__icone {
  margin: 0 15px 0 10px;
}

.adminNav.active .adminNav__link__icone {
  margin: auto;
}

.adminNav__link.active {
  background-color: #61e0e6;
}
.adminNav__link:hover {
  background-color: #61e0e6;
  color: white;
}
.adminNav__close {
  bottom: 40px;
  right: -20px;
}

.adminNav.active .adminNav__close {
  width: 100%;
  display: flex;
  justify-content: center;
  right: 0;
  bottom: 10px;
}

.adminNav__close-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.adminNav__close i {
  color: rgb(143, 140, 140);
}
.adminNav__close i:hover {
  color: #000;
}

.adminNav.active .adminNav__close i:hover {
  color: #fff;
}

.adminNav__humburger {
  display: none;
}
@media (max-width: 1502px) {
  .adminNav {
    width: 23%;
  }
}
@media (max-width: 1305px) {
  .adminNav {
    width: 25%;
  }
}

@media (max-width: 1200px) {
  .adminNav.active {
    width: 9%;
  }

  .adminNav {
    width: 28%;
  }
}
@media (max-width: 1084px) {
  .adminNav {
    width: 30%;
  }
}
@media (max-width: 1001px) {
  .adminNav {
    width: 33%;
  }
}
@media (max-width: 912px) {
  .adminNav {
    width: 35%;
  }
}
@media (max-width: 854px) {
  .adminNav.active {
    width: 13%;
  }
  .adminNav {
    width: 40%;
  }
}
@media (max-width: 750px) {
  .adminNav {
    width: 45%;
  }

  .adminNav.active {
    width: 16%;
  }
}
@media (max-width: 676px) {
  .adminNav {
    position: fixed;
    top: 0;
    left: auto;
    right: -500px;
    width: 55%;
    z-index: 99;
    overflow: auto;
  }
  .adminNav::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }

  .adminNav::-webkit-scrollbar-track {
    background: rgb(65, 63, 63); /* color of the tracking area */
  }

  .adminNav::-webkit-scrollbar-thumb {
    background-color: rgb(146, 145, 145); /* color of the scroll thumb */
    /* border-radius: 20px; roundness of the scroll thumb */
    /* border: 3px solid rgb(0, 0, 0); creates padding around scroll thumb */
  }

  .adminNav.active {
    width: 50%;
    right: 0;
  }

  .adminNav__close {
    display: none;
  }

  .adminNav.active .adminNav__close {
    display: none;
  }

  .adminNav__humburger {
    display: block;
    position: fixed;
    top: 10px;
    right: 15px;
    z-index: 99;
  }
  .adminNav.active .adminNav__humburger i {
    color: #fff;
  }

  .adminNav.active .adminNav__link p {
    display: block;
  }

  .adminNav.active .adminNav__link__icone {
    margin: 0 10px;
  }
}

@media (max-width: 676px) {
  .adminNav.active {
    width: 58%;
  }
}
@media (max-width: 500px) {
  .adminNav.active {
    width: 100%;
  }

  .adminNav.active .adminNav__nouvelles_commandes__indicator {
    font-size: 14px;
    background-color: black;
    color: rgb(233, 233, 233);
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
}
