@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif !important;
  overflow: auto !important;
}

.App {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
}

:root {
  /* --font-size: 18px;
    --font-size-mobile: 12px;
    --title-font-size: 46px;
    --title-font-size-mobile: 20px;
    --subtitle-font-size: 24px;
    --font-weight: 400;
    --title-font-weight: 700; */
  --primary-color: #3cb7e7;
  /* --primary-color-light: #C893C7;
    --primary-color-medium: #8E5A76;
    --secondary-color: #06ADEF;
    --secondary-color-light: #83D6F7;
    --secondary-color-navbar: #43C7F4;
    --secondary-color-variation: rgba(167, 209, 226, 0.75);
    --shadow-color: #C5C5C5;
    --opacity: 0.6; */
}

.adminPage {
  position: relative;
}

.adminPage__container {
  display: flex;
}

.admin__container {
  width: 90%;
  margin: 2rem auto 2rem;
}

@media (max-width: 1250px) {
  .admin__container {
    width: 100%;
    margin: 10px;
  }
}

@media (max-width: 1000px) {
  .admin__container {
    padding-top: 1rem;
  }
}
@media (max-width: 676px) {
  .admin__container {
    padding-top: 2rem;
  }
}

/* Changing Material Ui styles */
/* Input */
#dashboard-outlined-basic {
  padding: 0 0.8rem !important;
  /* height: 7.5rem !important; */
  border-bottom: #3cb7e7 !important;
  border-bottom: var(--primary-color) !important;
  box-shadow: #3cb7e7 !important;
  box-shadow: var(--primary-color) !important;
  padding-bottom: 0;
}
/* Input line when focus */
#dashboard-outlined-basic:focus {
  border-bottom: #3cb7e7 !important;
  border-bottom: var(--primary-color) !important;
  box-shadow: #3cb7e7 !important;
  box-shadow: var(--primary-color) !important;
  padding: 0 0.8rem !important;
  padding-bottom: 0;
}
/* Input border color when focus */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3cb7e7 !important;
  border-color: var(--primary-color) !important;
}
/* Radio button filling */
/* .MuiRadio-colorSecondary.Mui-checked {
  color: var(--primary-color);
} */
/* Input label when focus*/
/* .MuiFormLabel-root.Mui-focused {
  color: var(--primary-color) !important;
} */
/* Checkbox filling when focused */
/* .MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--primary-color) !important;
} */

/* Don/Adhesion pagination */
ul.pagination li {
  box-shadow: 0px 20px 20px gray;
}

ul.pagination li a {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  width: 50px;
  height: 45px;
  margin: 0.5px;
  border: 1px solid gray;
}

::-webkit-input-placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

.adminNav {
  position: -webkit-sticky !important; /*Safari*/
  position: sticky !important;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #04295d;
  transition: all 0.3s;
  height: 100vh;
  width: 20%;
}

.adminNav.active {
  width: 7%;
}

.adminNav__header {
  padding: 1rem;
  display: flex;
  justify-content: left;
  color: white;
  width: 120px;
  height: 60px;
}

.adminNav__header img {
  background-size: cover;
}
.adminNav__header p {
  width: 70px;
  object-fit: contain;
  margin-top: 1em;
}

.adminNav.active .adminNav__header img {
  text-align: center;
}

.adminNav__title {
  text-align: center;
  color: white;
}

.adminNav.active .adminNav__title {
  display: none;
}

.adminNav__links {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.adminNav.active {
  overflow: hidden;
}

.adminNav__links::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.adminNav__links::-webkit-scrollbar-track {
  background: rgb(65, 63, 63); /* color of the tracking area */
}

.adminNav__links::-webkit-scrollbar-thumb {
  background-color: #61e0e6; /* color of the scroll thumb */
  /* border-radius: 20px; roundness of the scroll thumb */
  /* border: 3px solid rgb(0, 0, 0); creates padding around scroll thumb */
}

.adminNav__link {
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  background-color: white; /* #25bbe8 => aprés click */
  position: relative;
}

.adminNav__link:active {
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  background-color: white; /* #25bbe8 => aprés click */
  position: relative;
}

.adminNav__link-deconnexion {
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  background-color: red;
  position: relative;
}

.adminNav__nouvelles_commandes__indicator {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 5px;
  background-color: rgb(17, 17, 17);
  color: rgb(233, 233, 233);
  padding: 0 5px;
  font-size: 14px;
  border-radius: 50%;
}

.adminNav__link p {
  margin: 0;
  opacity: 1;
  text-align: left;
}

.adminNav__link-deconnexion p {
  margin: 0;
  opacity: 1;
  text-align: left;
}
.adminNav__link-deconnexion:hover {
  color: white;
}
.adminNav.active .adminNav__link p {
  display: none;
}

.adminNav.active .adminNav__link-deconnexion p {
  display: none;
}

.adminNav__link__icone {
  margin: 0 15px 0 10px;
}

.adminNav.active .adminNav__link__icone {
  margin: auto;
}

.adminNav__link.active {
  background-color: #61e0e6;
}
.adminNav__link:hover {
  background-color: #61e0e6;
  color: white;
}
.adminNav__close {
  bottom: 40px;
  right: -20px;
}

.adminNav.active .adminNav__close {
  width: 100%;
  display: flex;
  justify-content: center;
  right: 0;
  bottom: 10px;
}

.adminNav__close-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.adminNav__close i {
  color: rgb(143, 140, 140);
}
.adminNav__close i:hover {
  color: #000;
}

.adminNav.active .adminNav__close i:hover {
  color: #fff;
}

.adminNav__humburger {
  display: none;
}
@media (max-width: 1502px) {
  .adminNav {
    width: 23%;
  }
}
@media (max-width: 1305px) {
  .adminNav {
    width: 25%;
  }
}

@media (max-width: 1200px) {
  .adminNav.active {
    width: 9%;
  }

  .adminNav {
    width: 28%;
  }
}
@media (max-width: 1084px) {
  .adminNav {
    width: 30%;
  }
}
@media (max-width: 1001px) {
  .adminNav {
    width: 33%;
  }
}
@media (max-width: 912px) {
  .adminNav {
    width: 35%;
  }
}
@media (max-width: 854px) {
  .adminNav.active {
    width: 13%;
  }
  .adminNav {
    width: 40%;
  }
}
@media (max-width: 750px) {
  .adminNav {
    width: 45%;
  }

  .adminNav.active {
    width: 16%;
  }
}
@media (max-width: 676px) {
  .adminNav {
    position: fixed;
    top: 0;
    left: auto;
    right: -500px;
    width: 55%;
    z-index: 99;
    overflow: auto;
  }
  .adminNav::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }

  .adminNav::-webkit-scrollbar-track {
    background: rgb(65, 63, 63); /* color of the tracking area */
  }

  .adminNav::-webkit-scrollbar-thumb {
    background-color: rgb(146, 145, 145); /* color of the scroll thumb */
    /* border-radius: 20px; roundness of the scroll thumb */
    /* border: 3px solid rgb(0, 0, 0); creates padding around scroll thumb */
  }

  .adminNav.active {
    width: 50%;
    right: 0;
  }

  .adminNav__close {
    display: none;
  }

  .adminNav.active .adminNav__close {
    display: none;
  }

  .adminNav__humburger {
    display: block;
    position: fixed;
    top: 10px;
    right: 15px;
    z-index: 99;
  }
  .adminNav.active .adminNav__humburger i {
    color: #fff;
  }

  .adminNav.active .adminNav__link p {
    display: block;
  }

  .adminNav.active .adminNav__link__icone {
    margin: 0 10px;
  }
}

@media (max-width: 676px) {
  .adminNav.active {
    width: 58%;
  }
}
@media (max-width: 500px) {
  .adminNav.active {
    width: 100%;
  }

  .adminNav.active .adminNav__nouvelles_commandes__indicator {
    font-size: 14px;
    background-color: black;
    color: rgb(233, 233, 233);
    right: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

.modal {
    box-shadow: none !important;
    background-color: transparent !important;
    display: flex;
}
.dirigeant-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  /*border-bottom: 1px solid gray;*/
  font-weight: bold;
  color: #04295d;
}

.article__fournisseur {
  display: flex;
  flex-wrap: wrap;
}

.fournisseur_fact {
  text-align: center;
  position: relative;
  font-size: 2.5em;
}

.article__montantHT {
  display: flex;
  flex-wrap: wrap;
}

.montantHT_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
}

.article__montantTVA {
  display: flex;
  flex-wrap: wrap;
}

.montantTVA_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
  color: black;
}

.article__date {
  display: flex;
  flex-wrap: wrap;
}

.date_fact {
  text-align: center;
  position: relative;
  font-size: 1.2em;
  font-weight: bold;
}

.article__photo {
  display: flex;
  flex-wrap: wrap;
}

.article_button {
  display: flex;
  flex-wrap: wrap;
}

.photo_fact {
  border: 1px solid black;
  text-align: center;
  position: relative;
  padding: 0;
  margin: 1em auto;
  width: 100%;
  max-width: 800px;
  height: auto;
}
.article__button {
  display: flex;
  justify-content: space-between;
}
.registre__button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.supp_button {
  color: #fff;
}

.article__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.suivi_activite__jour_exacte-calendrier {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.suivi_activite__jour_exacte-calendrier label {
  margin: 0;
}

.reset_button {
  margin-left: 28rem;
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.res_button {
  display: flex;
  flex-wrap: wrap;
}
.main {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  justify-content: center;
}

.dirigeant-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  /*border-bottom: 1px solid gray;*/
  font-weight: bold;
  color: #04295d;
}

.adhesion__fournisseur {
  display: flex;
  flex-wrap: wrap;
}

.fournisseur_fact {
  text-align: center;
  position: relative;
  font-size: 2.5em;
}

.adhesion__montantHT {
  display: flex;
  flex-wrap: wrap;
}

.montantHT_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
}

.adhesion__montantTVA {
  display: flex;
  flex-wrap: wrap;
}

.montantTVA_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
  color: black;
}

.adhesion__date {
  display: flex;
  flex-wrap: wrap;
}

.date_fact {
  text-align: center;
  position: relative;
  font-size: 1.2em;
  font-weight: bold;
}

.adhesion__photo {
  display: flex;
  flex-wrap: wrap;
}

.adhesion_button {
  display: flex;
  flex-wrap: wrap;
}

.photo_fact {
  border: 1px solid black;
  text-align: center;
  position: relative;
  padding: 0;
  margin: 1em auto;
  width: 100%;
  max-width: 800px;
  height: auto;
}
.adhesion__button {
  display: flex;
  justify-content: space-between;
}
.registre__button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.supp_button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #ec412a;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.adhesion__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.suivi_activite__jour_exacte-calendrier {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.suivi_activite__jour_exacte-calendrier label {
  margin: 0;
}

.reset_button {
  margin-left: 28rem;
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.res_button {
  display: flex;
  flex-wrap: wrap;
}
.main {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  justify-content: center;
}

.dirigeant-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  /*border-bottom: 1px solid gray;*/
  font-weight: bold;
  color: #04295d;
}

.don__fournisseur {
  display: flex;
  flex-wrap: wrap;
}

.fournisseur_fact {
  text-align: center;
  position: relative;
  font-size: 2.5em;
}

.don__montantHT {
  display: flex;
  flex-wrap: wrap;
}

.montantHT_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
}

.don__montantTVA {
  display: flex;
  flex-wrap: wrap;
}

.montantTVA_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
  color: black;
}

.don__date {
  display: flex;
  flex-wrap: wrap;
}

.date_fact {
  text-align: center;
  position: relative;
  font-size: 1.2em;
  font-weight: bold;
}

.don__photo {
  display: flex;
  flex-wrap: wrap;
}

.don_button {
  display: flex;
  flex-wrap: wrap;
}

.photo_fact {
  border: 1px solid black;
  text-align: center;
  position: relative;
  padding: 0;
  margin: 1em auto;
  width: 100%;
  max-width: 800px;
  height: auto;
}
.don__button {
  display: flex;
  justify-content: space-between;
}
.registre__button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

span {
  font-weight: normal;
}

.supp_button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #ec412a;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.don__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.suivi_activite__jour_exacte-calendrier {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.suivi_activite__jour_exacte-calendrier label {
  margin: 0;
}

.reset_button {
  margin-left: 28rem;
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.res_button {
  display: flex;
  flex-wrap: wrap;
}
.main {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  justify-content: center;
}

.suivi_activite__jour {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  border-bottom: 2px solid rgb(129, 128, 128);
}

.suivi_activite__jour__CA,
.suivi_activite__jour__operations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}
.suivi_activite__jour__CA-heading span {
  font-size: 1rem;
}
.suivi_activite__jour__CA p,
.suivi_activite__jour__operations p {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #4cbc4a;
  font-weight: 700;
}

.suivi_activite__jour__CA__card,
.suivi_activite__jour__operations__card {
  margin-top: 1rem;
  padding: 2rem;
  border: 1px solid #4cbc4a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.suivi_activite__jour__CA__card h1,
.suivi_activite__jour__operations__card h1 {
  font-size: 3rem;
}

.suivi_activite__jour_exacte {
  margin-top: 2rem;
}

.suivi_activite__jour_exacte-calendrier {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.suivi_activite__jour_exacte-calendrier label {
  margin: 10px !important;
}
.suivi_activite__jour_exacte .suivi_activite__jour {
  border: none;
}

.suivi_activite__jour_exacte
  .suivi_activite__jour
  .suivi_activite__jour__CA__card,
.suivi_activite__jour_exacte
  .suivi_activite__jour
  .suivi_activite__jour__operations__card {
  border: 2px solid #c4c4c4;
}

.suivi_activite__jour_exacte .suivi_activite__jour .suivi_activite__jour__CA p,
.suivi_activite__jour_exacte
  .suivi_activite__jour
  .suivi_activite__jour__operations
  p {
  color: black !important;
}

.suivi_activite__stripe {
  border-top: 2px solid rgb(129, 128, 128);
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.suivi_activite__stripe h1 {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 10px;
}

.suivi_activite__stripe .link {
  padding: 5px 15px;
  font-size: 1.3rem;
  border: none;
  background-color: #635bff;
  border-radius: 2px;
}


@media (max-width: 550px) {
  .suivi_activite__jour {
    flex-direction: column;
  }

  .suivi_activite__jour__operations {
    margin-top: 2rem;
  }
}

.dirigeant-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  /*border-bottom: 1px solid gray;*/
  font-weight: bold;
  color: #04295d;
}
.contact__fournisseur {
  display: flex;
  flex-wrap: wrap;
}

.fournisseur_fact {
  text-align: center;
  position: relative;
  font-size: 2.5em;
}

.contact__montantHT {
  display: flex;
  flex-wrap: wrap;
}

.email {
  text-align: center;
  position: relative;
}

.contact__montantTVA {
  display: flex;
  flex-wrap: wrap;
}

.montantTVA_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
  color: black;
}

.contact__date {
  display: flex;
  flex-wrap: wrap;
}

.date_fact {
  text-align: center;
  position: relative;
  font-size: 1.2em;
  font-weight: bold;
}

.contact__photo {
  display: flex;
  flex-wrap: wrap;
}

.contact_button {
  display: flex;
  flex-wrap: wrap;
}

.photo_fact {
  border: 1px solid black;
  text-align: center;
  position: relative;
  padding: 0;
  margin: 1em auto;
  width: 100%;
  max-width: 800px;
  height: auto;
}
.contact__button {
  display: flex;
  justify-content: space-between;
}
.registre__button {
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  width: 5em;
  height: 1.7em;
  padding: 0;
  margin: 1em auto;
}

.supp_button {
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #ec412a;
  width: 5em;
  height: 1.7em;
  padding: 0;
  margin: 1em auto;
}

.contact__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.suivi_activite__jour_exacte-calendrier {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.suivi_activite__jour_exacte-calendrier label {
  margin: 0;
}

.reset_button {
  margin-left: 28rem;
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 1.5em;
  padding: 0;
  margin: 1em auto;
}

.res_button {
  display: flex;
  flex-wrap: wrap;
}
.main {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  justify-content: center;
}

.icon {
  width: 5%;
}
.unseen-icon {
  color: Tomato;
}

.container__message {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  overflow-wrap: break-word;
  min-width: 250px;
}

/* Darker chat container */
.darker {
  border-color: #ccc;
  background-color: #ddd;
}

/* Clear floats */
.container__message::after {
  content: "";
  clear: both;
  display: table;
}

/* Style images */
.container__message img {
  float: left;
  max-width: 60px;
  margin-right: 20px;
  border-radius: 50%;
}

/* Style the right image */
.container__message img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}

.message-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.avatar {
  background-color: "#04295D";
}

.dirigeant-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  /*border-bottom: 1px solid gray;*/
  font-weight: bold;
  color: #04295d;
}

.journal__fournisseur {
  display: flex;
  flex-wrap: wrap;
}

.fournisseur_fact {
  text-align: center;
  position: relative;
  font-size: 2.5em;
}

.journal__montantHT {
  display: flex;
  flex-wrap: wrap;
}

.montantHT_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
}

.journal__montantTVA {
  display: flex;
  flex-wrap: wrap;
}

.montantTVA_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
  color: black;
}

.journal__date {
  display: flex;
  flex-wrap: wrap;
}

.date_fact {
  text-align: center;
  position: relative;
  font-size: 1.2em;
  font-weight: bold;
}

.journal__photo {
  display: flex;
  flex-wrap: wrap;
}

.journal_button {
  display: flex;
  flex-wrap: wrap;
}

.photo_fact {
  border: 1px solid black;
  text-align: center;
  position: relative;
  padding: 0;
  margin: 1em auto;
  width: 100%;
  max-width: 800px;
  height: auto;
}
.journal__button {
  display: flex;
  justify-content: space-between;
}
.registre__button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.supp_button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #ec412a;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.journal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.suivi_activite__jour_exacte-calendrier {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.suivi_activite__jour_exacte-calendrier label {
  margin: 0;
}

.reset_button {
  margin-left: 28rem;
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.res_button {
  display: flex;
  flex-wrap: wrap;
}
.main {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  justify-content: center;
}

.dirigeant-header h1 {
    text-align: center;
    font-size: 2.5rem;
    padding-bottom: 1em;
    /*border-bottom: 1px solid gray;*/
    font-weight: bold;
    color: #04295d;
  }
  
  .centre__fournisseur {
    display: flex;
    flex-wrap: wrap;
  }
  
  .fournisseur_fact {
    text-align: center;
    position: relative;
    font-size: 2.5em;
  }
  
  .centre__montantHT {
    display: flex;
    flex-wrap: wrap;
  }
  
  .montantHT_fact {
    text-align: center;
    position: relative;
    font-size: 1.8em;
  }
  
  .centre__montantTVA {
    display: flex;
    flex-wrap: wrap;
  }
  
  .montantTVA_fact {
    text-align: center;
    position: relative;
    font-size: 1.8em;
    color: black;
  }
  
  .centre__date {
    display: flex;
    flex-wrap: wrap;
  }
  
  .date_fact {
    text-align: center;
    position: relative;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .centre__photo {
    display: flex;
    flex-wrap: wrap;
  }
  
  .centre_button {
    display: flex;
    flex-wrap: wrap;
  }
  
  .photo_fact {
    border: 1px solid black;
    text-align: center;
    position: relative;
    padding: 0;
    margin: 1em auto;
    width: 100%;
    max-width: 800px;
    height: auto;
  }
  .centre__button {
    display: flex;
    justify-content: space-between;
  }
  .registre__button {
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3ebde6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0;
    margin: 1em auto;
  }
  
  .supp_button {
    color: #fff;
  }

  .centre__container {
    width: 80%;
  }

  .centre__insider {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .centre__insider .left {
    width: 50%;
    margin-right: 5%;
  }

  .centre__insider .right {
      top: -10%;
  }
  
  .suivi_activite__jour_exacte-calendrier {
    margin: auto;
    max-width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .suivi_activite__jour_exacte-calendrier label {
    margin: 0;
  }
  
  .reset_button {
    margin-left: 28rem;
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3ebde6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0;
    margin: 1em auto;
  }
  
  .res_button {
    display: flex;
    flex-wrap: wrap;
  }
  .main {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .MuiAccordionDetails-root {
    display: flex;
    padding: 8px 16px 16px;
    justify-content: center;
  }
  
  /* POP UP EDIT - ADD */
  .popUp{
      position:absolute;
      display: block;
      top:0;
      left: 0;
      width: 100%;
      height: 210%;
      background-color: rgba(0,0,0,0.5);
      z-index: 999999999999999;
  }

  .popUp__insider__centre{
      margin: auto;
      margin-top: 5%;
    width: 80%;
    height: 90%;
    padding-top: 2%;
    background-color: white;
    border: 5px solid #43c7f4;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .popUp__insider{
    margin: auto;
    margin-top: 5%;
  width: 80%;
  height: 48%;
  padding-top: 2%;
  background-color: white;
  border: 5px solid #43c7f4;
  justify-content: center;
  align-items: center;
  text-align: center;
}

  .popUp__insider input, .popUp__insider__centre input {
    width: 50% !important;
    border-bottom: 1px solid #43c7f4!important ;
    margin-bottom: 2% !important;
  }

  .popUp__insider input:focus, .popUp__insider__centre input:focus {
    border-bottom: 3px solid #90268e!important ;
  }

.ajouter__button {
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3ebde6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0;
    margin: 1em auto;
  }

  .noteb{
    margin: auto;
    background-color: #F9E4B7;
    border-radius: 5px;
    width: 90%;
  }
/* ::placeholder {
  color: black;
  opacity: 1;
}

:-ms-input-placeholder {
  color: black;
}

::-ms-input-placeholder {
  color: black;
} */

.click2FA{
  color: #61e0e6;
  cursor: pointer;
}

/* Create background with the picture APMF */
.team {
    background-image: url(/static/media/apmf.eac23715.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 6rem;
}
  
/* Position for the team_title */
.team_header {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  margin-top: -20px;
  padding: 100px 20px 500px 20px;
}
  
/* Style for the text in the center of the picture */
.team_title {
  font-family: "Roboto", sans-serif;
  font-size: var(--subtitle-font-size);;
  background-color: var(--primary-color-medium);
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 20px 40px 20px 40px;
}
.team_title b{
  font-size: var(--subtitle-font-size);;
}

/* Style for the text in the bottom of the picture */
.textBienvenue {
  padding: 5%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  min-height: 300px;
  background-color: var(--primary-color-light);
  color: white;
}
  
/* Mobile version */
@media screen and (max-width: 700px) {

  /* Resize 2 texts for mobile version */
  .team_title {
    font-size: 15px;
  }
  .textBienvenue {
    margin-top: -150px;
    padding-top: 55px;
    font-size: 15px;
    min-height: 270px;

  }

}

  
  


/* Re-position component InfoOne: 10px from all */
.infoOne {
    padding: 10px;
    margin-top: 2rem;
}


/* change background of the component HomeActualite into white, with no border */
.container {
    background-color: white;
    border: 0 solid #dedede;
}


/* Only image in this component have max-width 100%, no margin, no radius */
.container img {
    width: 100%;
    margin-right: 0;
    border-radius: 0;
}

/* Size and position all image */
.imgOne, .imgTwo, .imgThree, .imgFour {
    width: 120%;
}


/* desktop version */
@media screen and (min-width: 1200px) {

    /* Position image 1, 2, 3, 4 */
    .imgOne, .imgTwo, .imgThree, .imgFour {
        padding: 0px;
    }

   

    .imgTwo {
        padding-top: 60px;  
        margin-left: -55px;
    }
    
    .imgThree {
        margin-top: -70px;
        padding-right: 25px;
    }
    
    .imgFour {
        padding-top: 20px;
        margin-left: -55px;
    }


}

/* Mobile version */
@media screen and (max-width: 750px) {
    /* Help responsive for mobile version */
    .col {
        flex-basis: 100%;
    }

    /* Size and position all image */
    .imgOne, .imgTwo, .imgThree, .imgFour {
        max-width: 100%;
        padding-top: 20px;
    }
}
/* Position component InfoTwo 2% form left and right */
.infoTwo {
    padding: 0 1%;
    margin-bottom: 3rem;
    margin-top: -1rem;

}

/* Set background color and radius for the panel */
.asso__card-panel {
    background-color: var(--secondary-color-light)!important;
    border-radius: 20px!important;
    padding: 1em;
    margin-bottom: 0.5rem;
    height: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Grid display */
.infoTwo__container {
    display: grid;    
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
    grid-row-gap: 1em;
    row-gap: 1em;
    width: 80%;
    margin: auto;
}



/* Resize image Icon (Heart, Book, ...) to 50% for width and margin 30px for the bottom */
.imgIcone img{
    width: 75%;
    margin-bottom: 13px;
}

/* Margin 10px for the bottom to line up */
.asso__cardFooter {
    margin-bottom: 10px;
}

.asso__card-title {
    text-align: center;
    font-size: var(--subtitle-font-size);
    
}



.asso__card-body {
    margin-bottom: 2rem;
    height: 15em;
}
.btnPlus {
    border-radius: 20px !important;
    background-color: var(--secondary-color);
    color: white;
    border: solid 0px;
    font-size: var(--subtitle-font-size);
}


@media screen and (max-width: 750px) {
    .asso__card-body {
        font-size: 12px;
    }
    .infoTwo__container {
        grid-template-columns: 1fr;
    }
} 

@media only screen and (max-width: 1024px) and (min-width: 750px) {
    .asso__card-body {
        font-size: 12px;
    }
    .imgIcone img {
        width: 74%;
        margin-bottom: 0px;
    }
}

@media screen and (min-width: 750px) and (max-width: 1200px) {
    .infoTwo__container {
        grid-template-columns: 1fr 1fr;
    }
}

#adherer {
    margin-top: 2rem;
    padding-left: 70px;
    padding-right: 40px;
}


/* Style first paragraph */
.paragraph_one{
    padding: 0 150px 0 100px;
    justify-content: center;
    align-items: center;
    text-align: justify;
}

.paragraph_one_title svg{
    max-width: 100%;
}
.svg_line {
    padding-left: 0;
    margin-left: 0;
}
.paragraph_one h4 {
    text-align: justify;
    font-size: 1.7em;
    font-weight: normal;
}

/* style second paragraph */
.paragraph_two{
    position: absolute;
    padding: auto;
    margin-top: 7%; 
}

.paragraph_two p {
    background-color: var(--secondary-color-light)!important;
    color: white;
    width: 300px;
    height: 90px;
    margin: 20px auto;
    text-align: left;
    padding: 5% 15%;
}
.ImgGirl {
    max-width: 80%
}

/* style of the button Adhérer */
.btnAd {
    padding: 10px 60px;
    font-size: 26px;
    display: inline-block;
    border-radius: 60px!important;
    background-color: #90268E;
    color: white;
    border: solid 0px;
}
.btnAd:hover {
    background-color: #8E5A76;
}

/* bolder all text */
.paragraph_one p {
    font-weight: bolder;
}
.paragraph_one h4 {
    font-weight: bolder;
}
.paragraph_one h5 {
    font-weight: bolder;
}

/* Mobile version */

/* First paragraph mobile version */
.paragraph_one_mobile {
    padding: 130px 40px 0px 40px;
}

/* bolder all text */
.paragraph_one_mobile p {
    font-weight: bolder;
}
.paragraph_one_mobile h4 {
    font-weight: bolder;
}
.paragraph_one_mobile h5 {
    font-weight: bolder;
}
.paragraph_two_mobile{
    position: absolute;
    justify-content: right;
    align-items: right;
    text-align: right;
    margin-top: -170px;
    padding: 20px;
    /*padding-left: 25%;*/
}

.paragraph_two_mobile p {
    background-color: var(--secondary-color-light)!important;
    color: white;
    width: 60%;
    height: 30%;
    margin-top: 20px;
    text-align: left;
    padding: 2% 5%;
    font-size: 12px;
}



@media(max-width: 1100px) {
    #adherer {
        margin-top: 6rem;
        padding-left: 0px;
        padding-right: 0px;
    }
    .ImgGirl_mobile {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .paragraph_two_mobile{
        margin-top: -230px;
        margin-left: 80px;
        padding: 0px;
        /*padding-left: 25%;*/
    }
    .paragraph_two_mobile p {
        width: 80%;
        padding: 5% 5%;
        opacity: 0.95;
        font-weight: bold;
    }

}

@media(max-width: 800px) {
    .svg_line {
        display:none;
    }
   
    .btnAd {
        padding: 10px 50px;
        margin-left: 40px;
    }
    
}

.cardContainer__news-homepage {
    display: flex;
    flex-direction: column;
}
.card__new-homepage {
    max-width: 500px;
    max-height: 600px;

}

.cardImg {
    max-width: 100%;
    height: 20% auto;
    max-height: 200px;
}

/* Style of the title of card */
.cardTitle__news-homepage {
    color: #90268E;
    text-transform: uppercase;
    text-align: left;    
    font-size: var(--subtitle-font-size) !important; 
    font-weight: 300;
}

.cardDate__news-homepage {
    text-align: left;  
    font-size: var(--font-size) !important;
    font-weight: 300;
}

/* We set a maximum of three lines on the text */
.cardText__news-homepage {   
    --max-lines: 3;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    position: relative;
    padding-right: 2em; 
    margin-top: 1.5em;
    text-align: left;  
    font-size: var(--font-size) !important;
    font-weight: 300;
}

/*The ellipsis after the text is truncated */
.cardText__news-homepage::before {
    content: "(...)";
    position: absolute;
    right: 0;
    bottom: -3px;
    z-index: 100;
}

/* The white background to cover the text where the ellipsis appears */
.cardText__news-homepage::after {
    --max-lines: calc(3 - 1);
    content: '';
    position: absolute;
    top: calc(var(--lh) * var(--max-lines));
    right: 0;
    width: 2em;
    height: 1.5em;
    background: white;
}

.cardFooter__news-homepage {
    margin-bottom: 1rem;
    padding: 1rem;
    text-align: center;
}

.btnPlus { 
    border-radius: 20px !important;
    background-color: var(--secondary-color);
    color: white;
    border: solid 0px;
    font-size: var(--subtitle-font-size);
}
.btnPlus:hover {
    background-color: #90268E;
}
#HomeActualite {
    margin: 3rem 0;
    margin-top: 3rem;
}

#HomeActualite h4{
    padding-bottom: 20px;
}

/* change background of the component HomeActualite into white, with no border */
.container{
    background-color: white;
    border: 0 solid #dedede;
}

/* Only image in this component have max-width 100%, no margin, no radius */
.card-img-top {
    max-width: 100%;
    margin-right: 0;
    border-radius: 0;
}

/* Grid display */
.homeactualite-news {
    display: grid;    
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
    grid-row-gap: 1em;
    row-gap: 1em;
}

/* Style for the button "Voir tous nos actualités" */
.btnActual {
    border-radius: 60px!important;
    background-color: var(--secondary-color);
    color: white;
    border: solid 0px;
    padding: 8px 15px;
    font-size: var(--subtitle-font-size);;
}
.btnActual:hover {
    background-color: #90268E;
}

/* Position for the button "Voir tous nos actualités" from the top 20px */
.ActualFooter {
    padding-top: 10px;
}


@media screen and (max-width: 1200px) {
    .homeactualite-news {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 750px) {
    .homeactualite-news {
        grid-template-columns: 1fr;
    }
}
#contactForm {
    margin: 2rem 0;
}

/* bold text 100px and position in center*/
.container-contactForm h4 {
    font-weight: 100px;
    text-align: center;
    margin-bottom: 1rem;
}

/* make higher 200px */
.inscreaseHeight {
    height: 180px;
}

/* style button Valider */
.btnValider {
    border-radius: 60px!important;
    background-color: var(--secondary-color);
    color: white;
    border: solid 0px;
    padding: 8px 15px;
    font-size: var(--subtitle-font-size);;
    margin: 0 auto;
}

/* change background of the component ContactForm into white, with no border */
.container-contactForm{
    background-color: white;
    border: 0 solid #dedede;
}

/* Only image in this component have max-width 100%, no margin, no radius */
.container-contactForm img {
    max-width: 100%;
    max-height: 65%;
    margin-right: 0;
    border-radius: 0;
}

/* Mobile version */
@media screen and (max-width: 700px) {
    /* Help responsive for mobile version */
    .col {
        flex-basis: 100%;
    }
}
/* logo to the center on mobile & ipad version */
.logo__APMF{
    margin: 0 auto;
    height: 6.5em;
}


/* Pptision of the navbar 10px to the left and right */
.nav-wrapper {
    margin: 0 10px;
}
#menu-list-grow {
    margin-top: 11px;
    background: transparent;
    min-width: 210px;
    padding: 0;
}

/* For sub menu color */
.navlink--submenu {
    color: rgb(247, 244, 244) !important;
    display: block;
    font-size: 14px;
    margin-top: 1px;
    padding: 1em;
    text-align: left;
    background-color: #90268E;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-transform: none !important;
}

.navlink--submenu:hover {
    text-decoration: none;
    background-color: rgba(182, 63, 63, 0.04);
}

/* For sub menu color */
.navlink--submenu:hover {
    color: #90268E !important;
}

/* style of button Nous Soutenir */
.btnSou {
    border-radius: 60px!important;
    background-color: var(--secondary-color-navbar);
    color: white;
    border: solid 0px;
    padding: 15px 40px;
    display: inline;
}

/* Position to center */
.centerNav {
    padding-right: 14%;
}

/* Position to the right */
.rightBTN {
    padding-right: 2%;
}

/* increase height of navbar */
nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
    height: 100px;
    line-height: 100px;
}

/* Color and size of text header */
.text__header {
    color: var(--primary-color);
    text-transform: none;
    font-weight: 400;
    font-size: 18px;
    margin: 0 1rem;
}


.text__header_mobile {
    color: var(--primary-color);
    text-transform: none;
    font-weight: 400;
    font-size: 18px;
    margin-left: -1.5rem;
}

.itemColor {
    background-color: var(--primary-color);
    border-radius: 60px;
}


.text__header_white {
    color: white;
    background-color: var(--primary-color);
    text-transform: none;
    font-weight: 400;
    font-size: 18px;
    margin: 0 1rem;
}

/* Style of Button Soutenir mobile version */
.btnSou__mobile {
    color: var(--secondary-color);
    text-transform: none;
    font-size: var(--font-size);
}

.hamburger-button__button {
    width: 30px !important;
    margin-right: 2em !important;
}

.listItem-mobile-navbar svg{
    color: black !important;
}

.list-items-non-collapsable:hover {
    background-color: transparent !important;
    text-decoration: none;
}

.nav-mobile{
    padding: 1em;
    cursor: pointer;
    z-index: 100;
    margin-left: 7%;
}

.nav-mobile:hover {
    text-decoration: none;
    background-color: transparent;
    color: white;
}

.nav-mobile-active {
    background-color: var(--primary-color) !important;
    border-radius: 25px;
}

@media screen and (max-width: 800px){
    .logo__APMF{
        margin: 0 auto;
        height: 5em;
    }
}


#nous-page h1 {
    font-weight: bold;
    font-size: var(--title-font-size);
    line-height: 56px;
    text-align: center;
    margin: 3rem 0;
}

.page-card__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.DownloadMembers {
    display: flex;
    padding: 1%;
}
@media(max-width:1180px ){
    .DownloadMembers {
        flex-direction: column;
    }
    .MembersImg {
        max-width: 700px
    }

}
@media(max-width:750px ){
    .MembersImg {
        max-width: 500px
    }
}
@media(max-width:550px ){
    .MembersImg {
        max-width: 320px
    }
}
#members-card__container {
    display: flex;
    flex-direction: column;
    width: 22%;
    margin: 1em 10px;
    min-width: 250px;
    margin: 5px 0;
}
#member-card {
    width: 283px;
}
#members-card__container img {
    width: 100%;
    height: 250px;
}

.member-information__container {
    border: 2px solid black;
    padding: 1.5em;
    font-family:  Alegreya;
    letter-spacing: 0.02em;
    text-align: center;
}

.member-information__container h2 {
    font-size: 1.15rem;
    line-height: 20px;
    text-align: center;
}

.member-information__container h4 {
    font-size: 14px;
    line-height: 15px;
    text-align: center;
}

.member-information__container p {
    font-size: 12px;
    line-height: 12px;
    white-space: pre-line;
    text-align: center;
    color: #383838;    
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 1em 0;
    width: 90%
}



#missions-card__container {
    display: flex;
    flex-direction: column;
    width: 22%;
    margin: 1em 10px;
    border: 1px solid black;
    padding: 1em;
    line-height: 21px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 300px;
}

#missions-card__container h2 {
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
    font-size: var(--font-size);
    line-height: 21px;
    margin: 1em 0;
}

#missions-card__container p {
    font-size: var(--font-size);
    text-align: left;
    margin: 1em 0;
}

/* Style title member */
.Comite-member-partie h5{
    text-align: center;
    padding: 10px 0;
}

/* Style title copresident */
.Comite-copresident-partie h5{
    text-align: center;
    padding: 10px 0;
}

/* Change style paragraph in Comite */
.Comite-text-partie .paragraph p {
    text-align: justify;
}

.titre_comite{

    font-weight: bold;
    font-size: var(--title-font-size);
    line-height: 56px;
    text-align: center;
    margin: 3rem 0;
}

.paiment {
    /* min-height: 92vh;
      display: flex;
      align-items: center; */
  }
  
  .paiement__container {
    margin-top: 100px !important;
    max-width: 600px;
    margin: 1rem auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px #e6e3e3;
    position: relative;
  }
  
  .paiement__close {
    position: absolute;
    top: 2px;
    right: 10px;
    cursor: pointer;
  }
  .paiement__close i,
  .paiement__back i {
    font-size: var(--subtitle-font-size);
    pointer-events: none;
  }
  
  .paiement__back {
    display: none;
    position: absolute;
    top: 2px;
    left: 10px;
    cursor: pointer;
  }
  
  .paiement__back.active {
    display: block;
  }
  
  .paiement__container h1 {
    text-align: center;
    font-size: var(--subtitle-font-size);;
  }
  
  .paiement__process {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  .paiement__process p {
    margin: 0;
  }
  
  .paiement__process--options,
  .paiement__process--options {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  
  .paiement__process--options span {
    margin-right: 10px;
    background-color: #ccc;
    padding: 2px 10px;
    border-radius: 50%;
    color: #fff;
  }
  
  .paiement__process hr {
    flex: 1 1;
    height: 1px;
    border: 0;
    background-color: lightgray;
    margin-bottom: 10px;
  }
  
  .paiement__process--options.active span {
    background-color: var(--primary-color);
  }
  
  .paiement__details__recapitualitfs,
  .paiement__details__modePaiement {
    padding: 10px 0;
  }
  
  .paiement__details__recapitualitfs h2,
  .paiement__details__modePaiement h2 {
    font-size: 1.2rem;
  }
  
  .paiement__details__recapitualitfs p {
    margin: 0;
  }
  
  .paiement__details__product__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
  
  .paiement__details__product__details-info-quantite {
    font-size: 15px;
    color: rgb(126, 125, 125);
  }
  
  .paiement__details__recapitualitfs-total {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0;
    padding: 0 0 10px;
    border-bottom: 1px solid lightgray;
  }
  
  .paiement__details__recapitualitfs-total p {
    font-weight: bold;
  }
  
  .paiement__details__recapitualitfs-total-prix {
    font-weight: 600;
  }
  
  .paiement--btn {
    text-align: center !important;
    background: rgb(77, 76, 76) !important;
    margin: auto !important;
    display: flex !important;
  }
  
  .paiement__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    /* padding: 1rem; */
  }
  
  .paiement__form #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
    margin-top: 10px;
  }
  
  .paiement__form .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  .paiement__form .StripeElement .CardField-number input:-webkit-autofill,
  .paiement__form .StripeElement .CardField-number input {
    -webkit-text-fill-color: var(--primary-color) !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
  
  .paiement__details__error {
    text-align: center;
    font-size: 14px;
    color: rgb(243, 83, 83);
    margin: 10px 0;
  }

  .paiement--btn-payer{
    background-color: unset;
    background-color: #90268E !important;
  }
  
  .paiement--btn-payer-text img {
    max-width: 30px;
    object-fit: contain;
  }

  .paiement--btn-payer-text {
    color: white !important;
  }
  
  .paiement__details__securise {
    margin: 10px auto 0;
    text-align: center;
    color: rgb(107, 107, 107);
    font-size: 13px;
  }
  
  .delivery-infos__container {
    padding: 6px 0 7px;
    border-bottom: 2px solid var(--primary-color);
    margin: 1rem 0 2.5rem !important;
  }
  
  @media (max-width: 600px) {
    .paiement__process--options span {
      background-color: #ccc;
      padding: 1px 5px;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
    }
  
    .paiement__process p {
      font-size: 12px;
    }
    .paiement__details__recapitualitfs,
    .paiement__details__modePaiement {
      padding: 16px 0;
    }
  }
  
.steps-form-donation {    
    border: 1px solid #8E8E8E;
    box-sizing: border-box;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2rem;
}

.buttons-don-page {
    margin-top: 2rem;
}

.btnSteps {    
    background: var(--primary-color);
    color: white;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    font-size: var(--font-size);
    font-weight: bold;
    cursor: pointer;
    display: inline;
}

.btnSteps:focus {
    background-color: var(--primary-color) !important;
}

.don-complementary-infos {
    margin-top: 5rem;
    font-style: italic;
    font-weight: 500;
    font-size: var(--subtitle-font-size);
}

.don-complementary-infos h2 {
    font-size: var(--subtitle-font-size);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0.5rem;
}
.don-complementary-infos p {
    font-size: var(--subtitle-font-size);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0.5rem;
}

/* Changing Material Ui styles */

/* Input */
.steps-form-donation #outlined-basic {
    padding: 0.5em!important;
    height: 1.5rem !important;
    border-bottom: var(--primary-color) !important;
    box-shadow: var(--primary-color) !important;
}

/* Input line when focus */
.steps-form-donation #outlined-basic:focus {
    border-bottom: var(--primary-color) !important;
    box-shadow: var(--primary-color) !important;
}

/* Input border color when focus */
.steps-form-donation .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color) !important;
}

/* Radio button filling */
.steps-form-donation .MuiRadio-colorSecondary.Mui-checked {
    color: var(--primary-color)
}

/* Input label when focus*/
.steps-form-donation .MuiFormLabel-root.Mui-focused {
    color: var(--primary-color) !important;
}

/* Checkbox filling when focused */
.steps-form-donation .MuiCheckbox-colorPrimary.Mui-checked {
    color: var(--primary-color) !important;
}


/* Style for temoignage text part */
.Temoignages-text {
    border: black 3px solid;
    border-radius: 10px;
    padding: 30px;   
    box-shadow: 2px 2px 2px 2px var(--shadow-color); 
}

.Temoignages-text p{
    padding: 10px;
    text-align: justify;
}

.Temoignages-text .Temoignages-author{
    font-weight: bolder;
    text-align: end;
}
.Temoignage-video-Youtube {
    margin-top: 40px;
}

/* Style for icon in the bottom of the box */
.icon-gauche, .icon-droite {
    margin-top: -2px;
    display: none;
}
.icon-gauche {
    text-align: start;
    padding-left: 8%;
}
.icon-droite {
    text-align: end;
    padding-right: 8%;
}

/* style for title */
#Temoignages-page h5 {
    padding-left: 25px;
}

/* youtube link style */
.Temoignage-video-Youtube {
    position:relative;
    padding-bottom: 45%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}
   
.Temoignage-video-Youtube iframe, .Temoignage-video-Youtube object, .Temoignage-video-Youtube embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.titre_video_youtube{

    display: flex;
    justify-content: center;
    font-weight: bolder;
    font-size: 1.64rem;
    margin-top: 2em;
}

/* Mobile version */
@media screen and (max-width: 750px) {
    /* Style for temoignage text part in Mobile version */
    .Temoignages-text {
        padding: 10px;    
    }
    .Temoignages-text p{
        padding: 5px;
        font-size: var(--font-size);
    }

    /* style for title in Mobile version */
    #Temoignages-page h5 {
        font-size: var(--subtitle-font-size);
    }
}
.Img_amicus{
    line-height: 150%;    
}

.Img_genzyme {
    line-height: 150%;
    height: 6.5rem;
    width: 25rem !important;
}

.Img_takeda{
    line-height: 150%;
}

.partenairesAPMF_contenaire{
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 1em;
    align-items: flex-start;

}

#partenairesAPMF-page h1 {
    font-size: var(--subtitle-font-size);
    font-weight: bold;
}

.text_partenaires h1:first-child {
    padding-bottom: 1.5em;
    padding-top: 1em;
    
}

.partenairesAPMF_subcontainer {
    width: 30%;
    
}

.partenairesAPMF-images {
    width: 300px;
}

.APMFPartenaires__subtitle {
    font-size: var(--subtitle-font-size);
    margin-bottom: 2rem;
    font-weight: bold;
}

.partenairesAPMF_subcontainer p {
    font-size: var(--font-size);
    margin-top: 2rem;
}

@media screen and (max-width: 1300px) {
    .partenairesAPMF_contenaire{
        flex-direction: column;
        margin: 0 2rem;
    }

    .partenairesAPMF_subcontainer {
        width: 100%;
        margin: 2rem 0;
    }

    .partenairesAPMF-images {
        width: 300px;
    }

    .Img_amicus{
        margin-top: 5em;    
    }
    
}


@media screen and (max-width: 415px) {
   

    .Img_genzyme {
    
        width: 21rem !important;
    }
    
}
#centres-page h2 {
    font-weight: bold;
    font-size: var(--subtitle-font-size);
}

.city {
    font-size: 30px;
    padding: 1.8%;
}
.centres-page h4 {
    font-weight: bold;
    text-align: center;
}
.centres-page h5 {
    font-size: var(--font-size);
}
.orga_title {
    text-align: center;
    font-weight: bold;
}
.orga {
    padding: 50px;
}
.line {
    border-top: 3px solid black;

}
.centres_p {
    
    text-align: center;
    white-space: pre-line;
}

.page-card-PS {
    margin-top: 1rem;
}

#centers-card__container {
    display: flex;
    flex-direction: column;
    width: 22%;
    align-items: center;
    text-align: center;
    padding: 0.4em;
    border: 1px solid black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 300px;
    min-height: 300px;
    max-height: 500px;
    margin: 0.3em;
    margin-bottom: 2em;
    justify-content: space-around;
}

#centers-card__container h2 {
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 5px;

}

#centers-card__container p {
    justify-content: center;
    font-size: 14px;
    line-height: 29px;
}

#orgas-card__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    border-top: 0px;
    padding: 2em;
    line-height: 21px;
    min-width: 350px;
}
.head {
    display: flex;
    flex-direction: row;
    padding-bottom: 2%;
}
#orgas-card__container h5 {
    margin-left: 50px;
}


#orgas-card__container h2 {
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
    font-size: var(--font-size);
    line-height: 21px;
    margin: 1em 0;
}

#orgas-card__container p {
    font-size: var(--font-size);
    text-align: left;
    margin: 1em 0;
}

@media(max-width: 1400px) {

    #orgas-card__container {   
        width: 100%;
        height: auto;
        padding: 0;

    }
    #orgas-card__container h5 {
        margin-left: 2px;
    }
   
    
}

@media(max-width: 800px) {
    
    .head {
        flex-direction: column;
    }
    .head img {
        max-width: 50%;
    }

    
}
/* Grid display */
#actualitesList-page {
    display: grid;    
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
    grid-row-gap: 1em;
    row-gap: 1em;
}


.Paginate {
    margin-top: 100px;
}

ul.pagination li {
    box-shadow: 0px 0px 0px gray;
  }

  ul.pagination li a {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    width: 50px;
    height: 45px;
    margin: 0.5px;
    border-radius: 60px;
    border: 1px solid gray;
  }
  .pagination li.active {
      background-color: rgba(250, 246, 246, 0);
  }
 


@media screen and (max-width: 1200px) {
    #actualitesList-page {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 750px) {
    #actualitesList-page {
        grid-template-columns: 1fr;
    }
}
/* style for Item */
.journalFabry-Item{
    border-bottom: solid 0.2px rgba(0, 0, 0, 0.236);
    padding: 5%;
    margin-bottom: 15px;
    height:80vh;
}
.journalFabry-Item img{
    width: 315px;
    height: 450px;
    margin: 10 auto;
    display: block;
}
.actualite-text-Download {
    float: left;
}
.Journal-text {
    padding-top: 2%;
}

#journalFabry-page h4 {
    font-size: var(--subtitle-font-size);
}

/* style pagination */

.Paginate {
    margin-top: 50px;
}
.ul.pagination li {
    box-shadow: 0px 0px 0px gray;
  }
  
  ul.pagination li a {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    width: 50px;
    height: 45px;
    margin: 0.5px;
    border-radius: 60px;
    border: 1px solid gray;
  }
  .pagination li.active {
      background-color: rgba(255, 255, 255, 0);
  }


@media(max-width: 1000px) {
    .journalFabry-Item{
        border-bottom: solid 0.2px black;
        padding: 5%;
        height:90vh;
    }
    .Paginate {
        margin-right: 2em !important;
    }
    .Paginate ul li a {
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        width: 20px;
        height: 20px;
        margin: 0.2px;
        border-radius: 60px;
        border: 1px solid gray;
        padding: 0px !important;
        margin-top: 20px;
      }
      
}

.header-page__container {
    position: relative;
}

.header-page__image {
    width: 100%;
    max-height:  700px;
}

.header-page__description {
    position: absolute;
    bottom: 0;    
    width: 80%;
    height: 420px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    background-color: var(--secondary-color-variation);
    color: white;
    font-weight: 400;
    padding: 6.5em 4.5em;
}

.header-page__description h1 {
    font-size: var(--title-font-size);
    text-align: center;
    font-weight: var(---title-font-weight);
    margin-bottom: 1em;
    text-transform: uppercase;
}

.header-page__description h2 {
    font-size: var(--subtitle-font-size);
    font-weight: var(---font-weight);
    margin-bottom: 1em;
}

@media screen and (max-width: 1050px) {
    .header-page__description {
        height: 345px;
        padding: 3em;
    }

    .header-page__description h1 {
        margin-bottom: 0.3em;
        font-size: var(--subtitle-font-size);
    }
}

@media screen and (max-width: 750px) {
    .header-page__description{
        height: 150px;
        display: none;
    }
    .header-page__description p {
        display: none;
    }
}
.submenu__container {
    background-color: var(--secondary-color-variation);
    font-size: 18px;
    padding: 4em 2em;   
    height: 100%;   
}

.submenu__container li {
    margin-bottom: 1em;
    cursor: pointer;
    width: -webkit-max-content;
    width: max-content;
    padding: 0.2em 0.5em;
}

.subMenu-active {
    background-color: var(--primary-color);
    border-radius: 25px;

}

/* responsive: the menu on the left only displayed on big sreens. The value matches 'lg' screen from Material UI => breakpoint chosen for the navbar */
@media screen and (max-width: 1280px) {
    .submenu__container {
        display: none;
    }
}


.page {
    min-height: 70vh;
    margin-top: 100px;
}

#page__informations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

#footer {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0px !important;
    background-color: #F6F6F6;
}

.footer_link {
    color: rgb(61, 60, 60);
}
#footer h5 {
    font-size: 17px;
}
.footer_link:hover {
    font-weight: bold;
    color: #90268E;
}
.icons_footer {
    display: flex;
    flex-direction: column;
}
.icons_footer a {
    margin-top: 10px;
    display: flex;
}
.icons_footer a p{
    margin-top: -5px;
    color: black;
    font-size: var(--subtitle-font-size);;
    margin-left: 20px;
}
.footer_down {
    display: flex;
    justify-content: center;
}
.footer_down_text {
    font-weight: 450;
}
@media(max-width: 800px) {
    .icons_footer {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

    }
    .icons_footer a{
        padding: 3%;
    }
    .icons_footer a p{
        display: none;
    }

}
.page__children-container {
    width: 100%;
    padding: 0 5rem;
}
.submenu__container {
    margin-top: 6em;
}

/* Make header bolder */
.page__children-container h3{
    padding-bottom: 3em;
    font-weight: bolder;
    text-align: left;
    font-size: var(--subtitle-font-size);
}

/* Make sub header bolder */
.page__children-container h5{
    font-weight: bolder;
    text-align: left;
    padding: 10px 0px;
}



/* changer style list with disc before <li> */
.page__children-container ul:not(.browser-default) > li {
    padding-left: 10px;
    list-style-type: disc !important;
}

/* Padding for list */
.page__children-container li {
    padding: 10px;
}

/* Re-position context if neccessary */
.context {
    padding-left: 40px;
    padding-top: 10px;
    font-size: var(--font-size);
}

/* Padding for text */
.context__Paragraph p {
    padding: 10px 0px;
    font-weight: normal;
}

/* Position for paragraph without <ul> */
.context__Paragraph {
    padding-left: 0px;
    padding-top: 10px;
    font-size: var(--font-size);
}

/* Position image of the context into center */
.context img {
    margin: 0 auto;
    width: 50%;
    display: block;
}

/* Position image of the context Paraggraph into center */
.context__Paragraph img {
    margin: 0 auto;
    display: block;
}

/* padding each paragraph 50px from the bottom */
.paragraph {
    padding-bottom: 50px;
}

/* Padding second list paragraph */
.second_context {
    padding-left: 30px;
}

/* Padding list in second list paragraph */
.second_context li{
    padding: 5px 0px;
}

/* Mobile version */
@media screen and (max-width: 1024px) {
    /* Make header bolder */
    .page__children-container h3{
        font-weight: bolder;
        text-align: left;
        font-size: var(--title-font-size);
    }

    /* Make sub header bolder */
    .page__children-container h5{
        font-weight: bolder;
        text-align: left;
        padding: 10px 0px;
        font-size: var(--title-font-size);
    }

    /* Title of the paragraph */
    .paragraph_title {
        padding-left: 10px;
    }

    /* changer style list with disc before <li> */
    .page__children-container ul:not(.browser-default) > li {
        padding-left: 10px;
        list-style-type: disc !important;
    }

    /* Padding for list */
    .page__children-container li {
        padding: 10px;
    }

    /* Re-position context if neccessary */
    .context {
        padding-left: 10px;
        padding-top: 10px;
        font-size: var(--font-size);
    }

    /* Padding for text */
    .context__Paragraph p {
        padding: 10px 0px;

    }

    /* Position for paragraph without <ul> */
    .context__Paragraph {
        padding-left: 0px;
        padding-top: 20px;
        font-size: var(--font-size);
    }

    /* Position image of the context into center */
    .context img {
        margin: 0 auto;
        width: 100%;
        display: block;
    }

    /* Position image of the context Paraggraph into center */
    .context__Paragraph img {
        margin: 0 auto;
        display: block;
        width: 100%;
    }

    /* padding each paragraph 50px from the bottom */
    .paragraph {
        padding-bottom: 25px;
    }

    /* Padding second list paragraph */
    .second_context {
        padding-left: 10px;
    }

    /* Padding list in second list paragraph */
    .second_context li{
        padding: 10px 0px;
    }

    /* Resize container for mobile version */
    .page__children-container {
        width: 100%;
        padding: 0 15px;
    }
}
/* Style text for download */
.text__Download{
    color: var(--secondary-color);
    font-style: italic;
    font-size: 16px;
}

@media screen and (max-width: 1024px) {
    /* Style text for download */
    .text__Download{
        color: var(--secondary-color);
        font-style: italic;
        font-size: 12px;
    }
}




.actualite-page__container {
  margin: 2rem 0;
  min-height: 68vh;
}

.actualite-details__container {
  margin: 3rem auto;
  width: 80%;
}

.go-back-link__actualites {
  font-size: var(--subtitle-font-size);
  font-weight: 600;
  line-height: 1;
  padding: 1rem;
  margin-left: 2rem;
  position: relative;
  border: none;
  background-color: #fff;
}

.go-back-link__actualites::before {
  color: var(--primary-color);
  border-style: solid;
  border-width: 0 0 2px 2px;
  content: "";
  display: block;
  height: 8px;
  left: 0;
  position: absolute;
  width: 8px;
  margin-top: -3px;
  top: 50%;
  transition: left 0.1s;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.actualite__Page-content {
  margin-bottom: 5rem;
}

/* style for image */
.actualite__Page-content img {
  margin: 0 auto;
  display: block;
  width: 50%;
}

/* style for download partie */
.actualite-Download {
  text-align: center;
  margin: 1.5rem auto 0;
  width: -webkit-max-content;
  width: max-content;
}

/* style cho actualite download */
.actualite-text-Download {
  color: var(--secondary-color);
  font-style: italic;
  font-weight: bold;
  text-align: center;
}

/* Style and position of the Actualite Page */

.actualite__Page-footer p {
  text-align: left;
}

/* Style image réseaux socials = Facebook, Twitter, Youtube */
.image-reseaux img {
  display: inline;
  width: 40px;
  padding-left: 5px;
  padding-top: 10px;
}

.center-actualite__Page {
  text-align: center;
  padding-bottom: 20px;
}


.not-found__page {
    min-height: 70vh;
    margin-top: 100px;
}

.container-not-found{
    width:100vw;
    height:70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    position: relative;
    left:6vmin;
    text-align: center;
  }
  
  .cog-wheel1, .cog-wheel2{
    -webkit-transform:scale(0.7);
            transform:scale(0.7);
  }
  
  .cog1, .cog2{
    width:40vmin;
    height:40vmin;
    border-radius:50%;
    border:6vmin solid var(--primary-color);
    position: relative;
  }
  
  
  .cog2{
    border:6vmin solid var(--secondary-color);
  }
  
  .top-not-found, .down-not-found, .left-not-found, .right-not-found, .left-top-not-found, .left-down-not-found, .right-top-not-found, .right-down-not-found{
    width:10vmin;
    height:10vmin;
    background-color: var(--primary-color);
    position: absolute;
  }
  
  .cog2 .top-not-found,.cog2  .down-not-found,.cog2  .left-not-found,.cog2  .right-not-found,.cog2  .left-top-not-found,.cog2  .left-down-not-found,.cog2  .right-top-not-found,.cog2  .right-down-not-found {
    background-color: var(--secondary-color);
  }
  
  .top-not-found{
    top:-14vmin;
    left:9vmin;
  }
  
  .down-not-found{
    bottom:-14vmin;
    left:9vmin;
  }
  
  .left-not-found{
    left:-14vmin;
    top:9vmin;
  }
  
  .right-not-found{
    right:-14vmin;
    top:9vmin;
  }
  
  .left-top-not-found{
    -webkit-transform:rotateZ(-45deg);
            transform:rotateZ(-45deg);
    left:-8vmin;
    top:-8vmin;
  }
  
  .left-down-not-found{
    -webkit-transform:rotateZ(45deg);
            transform:rotateZ(45deg);
    left:-8vmin;
    top:25vmin;
  }
  
  .right-top-not-found{
    -webkit-transform:rotateZ(45deg);
            transform:rotateZ(45deg);
    right:-8vmin;
    top:-8vmin;
  }
  
  .right-down-not-found{
    -webkit-transform:rotateZ(-45deg);
            transform:rotateZ(-45deg);
    right:-8vmin;
    top:25vmin;
  }
  
  .cog2{
    position: relative;
    left:-10.2vmin;
    bottom:10vmin;
  }
  
  .container-not-found h1{
    color:#142833;
  }
  
  .first-four-not-found{
    position: relative;
    left:6vmin;
    font-size:40vmin;
  }
  
  .second-four-not-found{
    position: relative;
    right:18vmin;
    z-index: -1;
    font-size:40vmin;
  }
  
  .wrong-para{
    font-family: "Montserrat", sans-serif;
    position: absolute;
    bottom:2vmin;
    padding:3vmin 12vmin 3vmin 3vmin;
    font-weight:600;
    color:#092532;
    font-size: 18px;
  }

  .wrong-para a{
      color: var(--primary-color);
      margin-top: 1rem;
  }

  .wrong-para a:hover{
    font-weight: bold;
    text-decoration: underline;
}

@media screen and (max-width: 800px) {
    .wrong-para{
        bottom:12vmin;
      }
}
.politique__header{
    padding-top: 10%;
    height: 100vh;
}

.politique__header h1 {
    text-align: center;
    margin: 1rem 0 1.5rem;
    position: relative;
    padding-bottom: 12px;
    color: #90268e;
    font-size: 2.3rem;
    font-weight: 750;
    letter-spacing: 1px;
}

.politique__header h1:before {
    margin: auto;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 25%;
    height: 5px;
    background: #90268e;
    border-radius: 10px;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

/* Position elements, and footer at the bottom */
.site {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: var(--font-size);
}
:root {
    --font-size: 1rem;
    --title-font-size: 46px;
    --subtitle-font-size: 1.30rem;
    --font-weight: 400;
    --title-font-weight: 700;
    --primary-color: #90268E;   
    --primary-color-light: #C893C7;
    --primary-color-medium: #8E5A76;
    --secondary-color: #06ADEF;
    --secondary-color-light: #83D6F7;
    --secondary-color-navbar: #43C7F4;
    --secondary-color-variation: rgba(167, 209, 226, 0.75);
    --shadow-color: #C5C5C5;
    --opacity: 0.6;
}

@media screen  and (max-width: 1000px) {
    :root {
        --title-font-size: 20px;
        --subtitle-font-size: 1.4rem;
    }    
}

/* For setting the ellipsis on the text overflow in HomeActualite.css */
html {
    --lh: 1.4rem;
    line-height: 1.4rem;
    line-height: var(--lh);
  }

/* style button En savoir plus */
.btnPlus {
    border-radius: 0% !important;
    background-color: #06ADEF;
    background-color: var(--secondary-color);
    color: white;
    border: solid 0px;
    padding: 5px 15px;
}

p, div, h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
}


article, aside, footer, header, nav, section {
    margin: 0;
}

button:focus {
    background-color: transparent;
}

a:hover {
    text-decoration: none;
}
#submenu-list li {
    color: white;
}





