/* ::placeholder {
  color: black;
  opacity: 1;
}

:-ms-input-placeholder {
  color: black;
}

::-ms-input-placeholder {
  color: black;
} */

.click2FA{
  color: #61e0e6;
  cursor: pointer;
}