#nous-page h1 {
    font-weight: bold;
    font-size: var(--title-font-size);
    line-height: 56px;
    text-align: center;
    margin: 3rem 0;
}

.page-card__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.DownloadMembers {
    display: flex;
    padding: 1%;
}
@media(max-width:1180px ){
    .DownloadMembers {
        flex-direction: column;
    }
    .MembersImg {
        max-width: 700px
    }

}
@media(max-width:750px ){
    .MembersImg {
        max-width: 500px
    }
}
@media(max-width:550px ){
    .MembersImg {
        max-width: 320px
    }
}