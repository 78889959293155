#centers-card__container {
    display: flex;
    flex-direction: column;
    width: 22%;
    align-items: center;
    text-align: center;
    padding: 0.4em;
    border: 1px solid black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 300px;
    min-height: 300px;
    max-height: 500px;
    margin: 0.3em;
    margin-bottom: 2em;
    justify-content: space-around;
}

#centers-card__container h2 {
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 5px;

}

#centers-card__container p {
    justify-content: center;
    font-size: 14px;
    line-height: 29px;
}
