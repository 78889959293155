#HomeActualite {
    margin: 3rem 0;
    margin-top: 3rem;
}

#HomeActualite h4{
    padding-bottom: 20px;
}

/* change background of the component HomeActualite into white, with no border */
.container{
    background-color: white;
    border: 0 solid #dedede;
}

/* Only image in this component have max-width 100%, no margin, no radius */
.card-img-top {
    max-width: 100%;
    margin-right: 0;
    border-radius: 0;
}

/* Grid display */
.homeactualite-news {
    display: grid;    
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1em;
    row-gap: 1em;
}

/* Style for the button "Voir tous nos actualités" */
.btnActual {
    border-radius: 60px!important;
    background-color: var(--secondary-color);
    color: white;
    border: solid 0px;
    padding: 8px 15px;
    font-size: var(--subtitle-font-size);;
}
.btnActual:hover {
    background-color: #90268E;
}

/* Position for the button "Voir tous nos actualités" from the top 20px */
.ActualFooter {
    padding-top: 10px;
}


@media screen and (max-width: 1200px) {
    .homeactualite-news {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 750px) {
    .homeactualite-news {
        grid-template-columns: 1fr;
    }
}