#members-card__container {
    display: flex;
    flex-direction: column;
    width: 22%;
    margin: 1em 10px;
    min-width: 250px;
    margin: 5px 0;
}
#member-card {
    width: 283px;
}
#members-card__container img {
    width: 100%;
    height: 250px;
}

.member-information__container {
    border: 2px solid black;
    padding: 1.5em;
    font-family:  Alegreya;
    letter-spacing: 0.02em;
    text-align: center;
}

.member-information__container h2 {
    font-size: 1.15rem;
    line-height: 20px;
    text-align: center;
}

.member-information__container h4 {
    font-size: 14px;
    line-height: 15px;
    text-align: center;
}

.member-information__container p {
    font-size: 12px;
    line-height: 12px;
    white-space: pre-line;
    text-align: center;
    color: #383838;    
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 1em 0;
    width: 90%
}


