/* Style title member */
.Comite-member-partie h5{
    text-align: center;
    padding: 10px 0;
}

/* Style title copresident */
.Comite-copresident-partie h5{
    text-align: center;
    padding: 10px 0;
}

/* Change style paragraph in Comite */
.Comite-text-partie .paragraph p {
    text-align: justify;
}

.titre_comite{

    font-weight: bold;
    font-size: var(--title-font-size);
    line-height: 56px;
    text-align: center;
    margin: 3rem 0;
}