.dirigeant-header h1 {
    text-align: center;
    font-size: 2.5rem;
    padding-bottom: 1em;
    /*border-bottom: 1px solid gray;*/
    font-weight: bold;
    color: #04295d;
  }
  
  .centre__fournisseur {
    display: flex;
    flex-wrap: wrap;
  }
  
  .fournisseur_fact {
    text-align: center;
    position: relative;
    font-size: 2.5em;
  }
  
  .centre__montantHT {
    display: flex;
    flex-wrap: wrap;
  }
  
  .montantHT_fact {
    text-align: center;
    position: relative;
    font-size: 1.8em;
  }
  
  .centre__montantTVA {
    display: flex;
    flex-wrap: wrap;
  }
  
  .montantTVA_fact {
    text-align: center;
    position: relative;
    font-size: 1.8em;
    color: black;
  }
  
  .centre__date {
    display: flex;
    flex-wrap: wrap;
  }
  
  .date_fact {
    text-align: center;
    position: relative;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .centre__photo {
    display: flex;
    flex-wrap: wrap;
  }
  
  .centre_button {
    display: flex;
    flex-wrap: wrap;
  }
  
  .photo_fact {
    border: 1px solid black;
    text-align: center;
    position: relative;
    padding: 0;
    margin: 1em auto;
    width: 100%;
    max-width: 800px;
    height: auto;
  }
  .centre__button {
    display: flex;
    justify-content: space-between;
  }
  .registre__button {
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3ebde6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0;
    margin: 1em auto;
  }
  
  .supp_button {
    color: #fff;
  }

  .centre__container {
    width: 80%;
  }

  .centre__insider {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .centre__insider .left {
    width: 50%;
    margin-right: 5%;
  }

  .centre__insider .right {
      top: -10%;
  }
  
  .suivi_activite__jour_exacte-calendrier {
    margin: auto;
    max-width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .suivi_activite__jour_exacte-calendrier label {
    margin: 0;
  }
  
  .reset_button {
    margin-left: 28rem;
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3ebde6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0;
    margin: 1em auto;
  }
  
  .res_button {
    display: flex;
    flex-wrap: wrap;
  }
  .main {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .MuiAccordionDetails-root {
    display: flex;
    padding: 8px 16px 16px;
    justify-content: center;
  }
  
  /* POP UP EDIT - ADD */
  .popUp{
      position:absolute;
      display: block;
      top:0;
      left: 0;
      width: 100%;
      height: 210%;
      background-color: rgba(0,0,0,0.5);
      z-index: 999999999999999;
  }

  .popUp__insider__centre{
      margin: auto;
      margin-top: 5%;
    width: 80%;
    height: 90%;
    padding-top: 2%;
    background-color: white;
    border: 5px solid #43c7f4;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .popUp__insider{
    margin: auto;
    margin-top: 5%;
  width: 80%;
  height: 48%;
  padding-top: 2%;
  background-color: white;
  border: 5px solid #43c7f4;
  justify-content: center;
  align-items: center;
  text-align: center;
}

  .popUp__insider input, .popUp__insider__centre input {
    width: 50% !important;
    border-bottom: 1px solid #43c7f4!important ;
    margin-bottom: 2% !important;
  }

  .popUp__insider input:focus, .popUp__insider__centre input:focus {
    border-bottom: 3px solid #90268e!important ;
  }

.ajouter__button {
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3ebde6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0;
    margin: 1em auto;
  }

  .noteb{
    margin: auto;
    background-color: #F9E4B7;
    border-radius: 5px;
    width: 90%;
  }