.not-found__page {
    min-height: 70vh;
    margin-top: 100px;
}

.container-not-found{
    width:100vw;
    height:70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    position: relative;
    left:6vmin;
    text-align: center;
  }
  
  .cog-wheel1, .cog-wheel2{
    transform:scale(0.7);
  }
  
  .cog1, .cog2{
    width:40vmin;
    height:40vmin;
    border-radius:50%;
    border:6vmin solid var(--primary-color);
    position: relative;
  }
  
  
  .cog2{
    border:6vmin solid var(--secondary-color);
  }
  
  .top-not-found, .down-not-found, .left-not-found, .right-not-found, .left-top-not-found, .left-down-not-found, .right-top-not-found, .right-down-not-found{
    width:10vmin;
    height:10vmin;
    background-color: var(--primary-color);
    position: absolute;
  }
  
  .cog2 .top-not-found,.cog2  .down-not-found,.cog2  .left-not-found,.cog2  .right-not-found,.cog2  .left-top-not-found,.cog2  .left-down-not-found,.cog2  .right-top-not-found,.cog2  .right-down-not-found {
    background-color: var(--secondary-color);
  }
  
  .top-not-found{
    top:-14vmin;
    left:9vmin;
  }
  
  .down-not-found{
    bottom:-14vmin;
    left:9vmin;
  }
  
  .left-not-found{
    left:-14vmin;
    top:9vmin;
  }
  
  .right-not-found{
    right:-14vmin;
    top:9vmin;
  }
  
  .left-top-not-found{
    transform:rotateZ(-45deg);
    left:-8vmin;
    top:-8vmin;
  }
  
  .left-down-not-found{
    transform:rotateZ(45deg);
    left:-8vmin;
    top:25vmin;
  }
  
  .right-top-not-found{
    transform:rotateZ(45deg);
    right:-8vmin;
    top:-8vmin;
  }
  
  .right-down-not-found{
    transform:rotateZ(-45deg);
    right:-8vmin;
    top:25vmin;
  }
  
  .cog2{
    position: relative;
    left:-10.2vmin;
    bottom:10vmin;
  }
  
  .container-not-found h1{
    color:#142833;
  }
  
  .first-four-not-found{
    position: relative;
    left:6vmin;
    font-size:40vmin;
  }
  
  .second-four-not-found{
    position: relative;
    right:18vmin;
    z-index: -1;
    font-size:40vmin;
  }
  
  .wrong-para{
    font-family: "Montserrat", sans-serif;
    position: absolute;
    bottom:2vmin;
    padding:3vmin 12vmin 3vmin 3vmin;
    font-weight:600;
    color:#092532;
    font-size: 18px;
  }

  .wrong-para a{
      color: var(--primary-color);
      margin-top: 1rem;
  }

  .wrong-para a:hover{
    font-weight: bold;
    text-decoration: underline;
}

@media screen and (max-width: 800px) {
    .wrong-para{
        bottom:12vmin;
      }
}