
.page {
    min-height: 70vh;
    margin-top: 100px;
}

#page__informations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
